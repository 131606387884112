import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <div className="flex">
        <div className="">
          <p>Besoin d’un rendez-vous, d’une information ?</p>
          <Link to='/contact'>Contactez-nous</Link>
        </div>
        <div className="">
          <p>Instagram</p>
          <a href='https://instagram.com/apresmidialacampagne?igshid=YmMyMTA2M2Y='>@apresmidialacampagne</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer